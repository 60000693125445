import React from "react";
import { Link as GatsbyLink } from "gatsby-plugin-intl";
import { Contentful_Link, Contentful_NavigationItem } from "graphql-types";

interface ExtendedProps {
  className?: string;
  role?: string;
  activeClassName?: string;
  onClick?: any;
  children?: any;
}

interface Extended_Contentful_Link extends ExtendedProps, Contentful_Link {
  internalPage: Contentful_Link["internalPage"] & {
    __typename: string;
  };
}
interface Extended_Contentful_NavigationItem extends ExtendedProps, Contentful_NavigationItem {
  internalPage: Contentful_Link["internalPage"] & {
    __typename: string;
  };
}

function Download(e: any, props: Extended_Contentful_Link | Extended_Contentful_NavigationItem) {
  if ((props as Extended_Contentful_Link).file && (props as Extended_Contentful_Link).file?.url) {
    const type = (props as Extended_Contentful_Link).file?.contentType;
    const data = (props as Extended_Contentful_Link).file?.url;
    if (type && data) {
      const href = window.URL.createObjectURL(new Blob([data], { type }));
      e.currentTarget.setAttribute("href", href);
      window.URL.revokeObjectURL(e.href);
    }
  }
}

function Link(props: Extended_Contentful_Link | Extended_Contentful_NavigationItem | any) {
  const openInNewTab = props.openInNewTab ?? false;
  const target = props.openInNewTab ? "_blank" : "_self";
  const role = props.role ? props.role : "link";

  /*
    RULE:
    The rule for the nav link is that an internal link
    takes priority and then if it's not internal, it's external.
    Otherwise it's not a link and not rendered.
  */
  if (props.internalPage) {
    if (!props.internalPage.urlSlug) return null;

    const slugPrefix =
      props.internalPage.__typename === "Contentful_StaffMember"
        ? "/staff"
        : props.internalPage.__typename === "Contentful_Article"
        ? "/article"
        : props.internalPage?.sys?.contentType?.sys?.id === "article"
        ? "/article"
        : "";
    const url = `${slugPrefix}/${props.internalPage.urlSlug}${
      props.internalPageQueryString ? props.internalPageQueryString : ""
    }`;
    return (
      <GatsbyLink
        to={url}
        target={target}
        role={role}
        title={props.label ?? ""}
        className={props.className}
        activeClassName={props.activeClassName}
        onClick={props.onClick}
      >
        {props.children && props.children}
        {!props.children && props.label}
      </GatsbyLink>
    );
  } else if (!props.internalPage && props.externalUrl) {
    return (
      <a href={props.externalUrl} target={target} role={role} className={props.className}>
        {props.children && props.children}
        {!props.children && props.label}
      </a>
    );
  } else if (
    !props.internalPage &&
    !props.externalUrl &&
    !openInNewTab &&
    (props as Extended_Contentful_Link).file &&
    (props as Extended_Contentful_Link).file?.url
  ) {
    return (
      <a
        data-href={(props as Extended_Contentful_Link).file?.url}
        onClick={(e) => Download(e, props)}
        className={`${props.className} cursor-pointer`}
        title={props.label ?? ""}
        download={(props as Extended_Contentful_Link).file?.fileName}
      >
        {props.children && props.children}
        {!props.children && props.label}
      </a>
    );
  } else if (
    !props.internalPage &&
    !props.externalUrl &&
    (props as Extended_Contentful_Link).file &&
    (props as Extended_Contentful_Link).file?.url
  ) {
    return (
      <a
        href={(props as Extended_Contentful_Link).file?.url ?? ""}
        target={target}
        title={props.label ?? ""}
        className={`${props.className} cursor-pointer`}
      >
        {props.children && props.children}
        {!props.children && props.label}
      </a>
    );
  } else if (props.internalPageQueryString && props.label) {
    return (
      <a href={props.internalPageQueryString} title={props.label ?? ""} className={`underline cursor-pointer`}>
        {props.label}
      </a>
    );
  }

  return null;
}

// export default Link;
export default React.memo(Link);
