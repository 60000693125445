import React from "react";
import classnames from "classnames";
import dayjs from "dayjs";
import { Link as LinkGatsby } from "gatsby";
import { Link as LinkGatsbyIntl } from "gatsby-plugin-intl";
import Image from "../image";
import { Contentful_Asset } from "graphql-types";
import { TileSize } from "./types";
import Badge, { BadgeProps } from "../badge";
import { textRender } from "../../utils/text-render";

export type TileProps = {
  url: string;
  title: string;
  descript?: string;
  date?: string;
  image?: Contentful_Asset | null;
  badge?: BadgeProps | null;
  isHighlighted: boolean;
  size: TileSize;
  count: number;
  intlLink?: boolean;
};

export function getSizeClassName(size: TileSize) {
  switch (size) {
    case TileSize.Tile:
      return "tile-ratio";
    case TileSize.Square:
      return "square-tile-ratio";
    case TileSize.Featured:
      return "featured-tile-ratio";
    default:
      return null;
  }
}

function Tile(props: TileProps) {
  const title = props.title ?? "";

  const descript = props.descript ?? "";
  const isFeatured = props.size === "featured";
  const headlineStyles = classnames("font-medium lg:text-2xl leading-tight", {
    "lg:text-4xl": props.isHighlighted,
  });
  const color = typeof process.env.RANDOMCOLORS !== "undefined" ? process.env.RANDOMCOLORS[props.count] : "slate";

  const Link = props.intlLink ? LinkGatsbyIntl : LinkGatsby;

  const htmlTitle = textRender(title);

  return (
    <div className="w-full font-medium text-slate-400 leading-tight mb-4 hover:opacity-80 transition-opacity duration-700">
      <div className="pb-4">
        <div className={classnames("relative", getSizeClassName(props.size))}>
          <div className="absolute inset-0 rounded-3xl overflow-hidden">
            {props.image && (
              <>
                <Image
                  image={props.image}
                  alt={title}
                  type={"fluid"}
                  className={`inset-0 z-0 rounded-3xl`}
                  style={{ position: "absolute" }}
                />
                {isFeatured && <div className="absolute inset-0 z-0 top-1/4 bg-gradient-to-t from-black opacity-50" />}
              </>
            )}
            {!props.image && <div className={`absolute inset-0 z-0 rounded-3xl bg-${color}-400`} />}

            {isFeatured && (
              <div className="absolute inset-0 z-10 flex flex-auto items-end">
                <div className="p-6 text-white">
                  <div className="line-clamp overflow-hidden">
                    <h3 className={headlineStyles}>{htmlTitle}</h3>
                  </div>
                </div>
              </div>
            )}

            <Link to={`${props.url}`} title={props.title ?? ""} className="absolute inset-0 z-10">
              <span />
            </Link>

            {props.badge && props.badge.text && (
              <div className="absolute z-20 pointer-events-none">
                <div className="p-6">
                  <Badge {...props.badge} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {!isFeatured && (
        <Link to={`${props.url}`} title={htmlTitle} className="pb-4">
          <h2 className={`${props.size === "tile" ? "text-2xl" : "text-2xl"} mb-1 leading-6`}>{htmlTitle}</h2>
          {props.descript && <p className="font-normal text-sm leading-4.5">{props.descript}</p>}
          {props.date && <p className="font-semibold text-slate-300 text-xs">{dayjs(props.date).format("DD MMM YYYY")}</p>}
        </Link>
      )}
    </div>
  );
}

Tile.defaultProps = {
  size: "tile",
  isHighlighted: false,
  intlLink: false,
};

export default React.memo(Tile);
