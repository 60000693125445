export enum FilterSortBy {
  PUBLISHED_DATE = "Published Date",
  RANDOM = "Random",
}

export enum CONTENT_TYPE {
  EVENT = "7gGYT0ETnm83XuKFWzLa8C",
}

export const SUPPORTED_REGIONS = [
  { code: "en-nz", label: "New Zealand" },
  { code: "en-au", label: "Australia" },
  { code: "pt-br", label: "Brazil" },
];
