export const isOSWindows = () => {
  const isSSR = typeof navigator === "undefined";
  if (isSSR) {
    return 0;
  }
  return navigator.platform.indexOf("Win") > -1;
};

export const isTouch = () => {
  const isSSR = typeof window === "undefined";

  if (isOSWindows()) {
    return false;
  }

  const prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
  const mq = function (query: any) {
    if (isSSR) {
      return false;
    }
    return window.matchMedia(query).matches;
  };
  if (!isSSR) {
    if ("ontouchstart" in window || (window.DocumentTouch && document instanceof DocumentTouch)) {
      return true;
    }
  }
  const query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join("");

  return mq(query);
};
