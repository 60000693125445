import React from "react";
import { Contentful_SiteSettingsSocialNetworkLinksCollection, Contentful_SocialNetworkLink, Maybe } from "graphql-types";
import SVG from "react-inlinesvg";
import Menu from "../components/menu";
import MenuItem from "../components/menu-item";
import Facebook from "../../static/images/icons/facebook.svg";
import Linkedin from "../../static/images/icons/linkedin.svg";
import Twitter from "../../static/images/icons/twitter.svg";
import Youtube from "../../static/images/icons/youtube.svg";
import Weibo from "../../static/images/icons/weibo.svg";
import Instagram from "../../static/images/icons/instagram.svg";

const Networks: any = {
  Facebook,
  Linkedin,
  Twitter,
  Youtube,
  Weibo,
  Instagram,
};

function FooterSocialNetworkLinks(collection: Contentful_SiteSettingsSocialNetworkLinksCollection) {
  return (
    <>
      <h5 className="sr-only">Social network links</h5>
      <Menu className="flex flex-wrap">
        {collection.items &&
          collection.items.map((item: Maybe<Contentful_SocialNetworkLink>, key: number) => {
            if (!item || !item.socialNetwork) return null;
            const Icon = Networks[item.socialNetwork.charAt(0).toUpperCase() + item.socialNetwork.slice(1)];
            if (!Icon) {
              return null;
            }
            return (
              <MenuItem {...item} className="mr-2 mt-2" key={key}>
                <a
                  href={item.externalUrl ?? ""}
                  title={item.title ?? ""}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={item.title ?? ""}
                  className="block hover:opacity-25"
                >
                  <Icon />
                </a>
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
}

// export default FooterSocialNetworkLinks;
export default React.memo(FooterSocialNetworkLinks);
