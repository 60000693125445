import React from "react";
import Menu from "../components/menu";
import MenuItem from "../components/menu-item";
import NavLink from "../components/link";
import { Contentful_NavigationItem, Contentful_SiteSettingsFooterHygieneLinksCollection, Maybe } from "graphql-types";

function FooterHygieneLinks(collection: Contentful_SiteSettingsFooterHygieneLinksCollection) {
  return (
    <>
      <h4 className="sr-only">Hygiene links</h4>
      <Menu className="md:flex">
        {collection.items &&
          collection.items.map((item: Maybe<Contentful_NavigationItem>, key: number) => {
            if (item)
              return (
                <MenuItem {...item} className="mr-2 md:mr-4 pb-2" key={key}>
                  <NavLink {...item} className="hover:underline" />
                </MenuItem>
              );

            return null;
          })}
      </Menu>
    </>
  );
}

// export default FooterHygieneLinks;
export default React.memo(FooterHygieneLinks);
