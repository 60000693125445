import React from "react";

export enum ContentTypeColour {
  CaseStudies = "blue",
  Podcasts = "purple",
  Articles = "yellow",
  News = "green",
  Events = "event",
  Default = "slate",
}

export enum ContentType {
  CaseStudies = "Case studies",
  Podcasts = "Podcasts",
  Articles = "Articles",
  News = "News",
  Events = "Event",
  CaseStudy = "Case study",
  Podcast = "Podcast",
  Article = "Article",
}

export function Type(label: string): ContentTypeColour {
  switch (label) {
    case ContentType.CaseStudies:
      return ContentTypeColour.CaseStudies;
    case ContentType.Podcasts:
      return ContentTypeColour.Podcasts;
    case ContentType.Articles:
      return ContentTypeColour.Articles;
    case ContentType.News:
      return ContentTypeColour.News;
    case ContentType.Events:
      return ContentTypeColour.Events;
    case ContentType.CaseStudy:
      return ContentTypeColour.CaseStudies;
    case ContentType.Podcast:
      return ContentTypeColour.Podcasts;
    case ContentType.Article:
      return ContentTypeColour.Articles;
    default:
      return ContentTypeColour.Default;
  }
}

// bg-slate-100 text-slate-500

// const colors = {
//   blue: "#03619c",
//   yellow: "#8c8f03",
//   red: "#9c0312",
// };

export const ThemeContext = React.createContext(ContentTypeColour.Default);
