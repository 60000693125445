import { Contentful_NavigationItem } from "graphql-types";
import Maybe from "graphql/tsutils/Maybe";
import React, { useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import Link from "./link";
import classNames from "classnames";
import gsap from "gsap";
import MenuItem from "./menu-item";
import Menu from "./menu";

function PrimaryMenuLink({ item }: { item: Maybe<Contentful_NavigationItem> }) {
  interface State {
    isItemExpanded: boolean;
    isSubnavVisible: boolean;
  }
  const [state, setState] = useState<State>({
    isItemExpanded: false,
    isSubnavVisible: false,
  });

  function onClickItem() {
    let shouldItemExpand = false;
    if (!state.isItemExpanded) {
      shouldItemExpand = true;
    }
    setState((prevState) => ({
      ...prevState,
      isItemExpanded: shouldItemExpand,
    }));
  }

  const useExpandableMenu: boolean = item?.childrenCollection?.items.length ? true : false;
  const expandButtonStyle = {
    minWidth: 30,
  };

  const subnavClassName = classNames("container mx-auto", {
    hidden: !state.isSubnavVisible,
  });

  const animationRefs: any = {
    subnavLinkRefs: useRef<(HTMLDivElement | null)[]>([]),
    containerButtonMore: useRef(null),
    containerBackground: useRef(null),
    containerItemsBackground: useRef(null),
  };

  function expendItem() {
    if (!state.isSubnavVisible) {
      gsap.fromTo(
        animationRefs.subnavLinkRefs.current,
        { opacity: 0, x: -10 },
        { opacity: 1, x: 0, ease: "power3.out", stagger: 0.04 }
      );

      gsap.fromTo(
        animationRefs.containerButtonMore.current,
        { rotation: "0deg" },
        { rotation: "135deg", duration: 0.6, ease: "power3.out" }
      );

      gsap.fromTo(
        [animationRefs.containerBackground.current, animationRefs.containerItemsBackground.current],
        {
          y: "0%",
          opacity: 0,
        },
        { y: "0%", opacity: 1, duration: 0.6, ease: "power3.out" }
      );

      setState((prevState) => ({
        ...prevState,
        isSubnavVisible: true,
      }));
    }
  }

  function collapseItem() {
    if (state.isSubnavVisible) {
      gsap.set(animationRefs.subnavLinkRefs.current, { opacity: 0 });
      gsap.to(animationRefs.containerButtonMore.current, { rotation: "0deg", ease: "power3.out" });

      gsap.to(animationRefs.containerBackground.current, {
        opacity: 0,
        duration: 0.2,
        delay: 0,
        ease: "power2.out",
      });

      gsap.set([animationRefs.containerItemsBackground.current], { opacity: 0 });

      setState((prevState) => ({
        ...prevState,
        isSubnavVisible: false,
      }));
    }
  }

  function resetAnimations() {
    let animationItems: Array<any> = [];
    for (const property in animationRefs) {
      if (animationRefs[property].current) {
        animationItems.push(animationRefs[property].current);
      }
    }
    gsap.killTweensOf(animationItems);
  }

  useEffect(() => {
    if (state.isItemExpanded) {
      expendItem();
    } else {
      collapseItem();
    }
    return () => {
      resetAnimations();
    };
  }, [state.isItemExpanded]);

  return (
    <>
      <div className="relative container mx-auto transition-colors duration-100 overflow-hidden">
        <div
          ref={animationRefs.containerBackground}
          className="absolute top-0 left-0 right-0 bottom-0 bg-slate-500 opacity-0"
        ></div>
        <div className="relative flex px-6 py-3 items-stretch">
          <div className="w-full">
            <Link {...item} className="font-medium" activeClassName="font-bold">
              <div className="w-full font-semibold text-lg">{item?.label}</div>
            </Link>
          </div>
          {useExpandableMenu && (
            <button onClick={onClickItem} style={expandButtonStyle} className="flex justify-end items-center">
              <div className="flex items-center">
                <div ref={animationRefs.containerButtonMore}>
                  <SVG src="/images/icons/more.svg" width="15" height="15" title="Expand menu" cacheRequests={true} />
                </div>
              </div>
            </button>
          )}
        </div>
      </div>
      <div className={subnavClassName}>
        {item?.childrenCollection && (
          <div className="relative container mx-auto">
            <div
              ref={animationRefs.containerItemsBackground}
              className="absolute top-0 left-0 bottom-0 right-0 bg-slate-500"
            ></div>
            <div className="relative">
              <Menu>
                {item?.childrenCollection.items.map((item: Maybe<Contentful_NavigationItem>, key: number) => {
                  return (
                    <MenuItem key={key} className="cursor-pointer">
                      <div
                        ref={(ref: HTMLDivElement) => {
                          animationRefs.subnavLinkRefs.current[key] = ref;
                        }}
                      >
                        <Link {...item} className="font-medium text-base" activeClassName="font-bold">
                          <div className="flex items-center px-6 py-3">
                            <div className="w-full text-base">{item?.label}</div>
                          </div>
                        </Link>
                        {item?.childrenCollection.items.filter((x) => x).length > 0 && (
                          <div className="px-6 inline-flex flex-col">
                            {item.childrenCollection.items
                              .filter((x) => x)
                              .map((x) => (
                                <Link
                                  key={x?.sys.id}
                                  className="mt-4 pl-6 last:pb-3 first:mt-0  inline-flex text-sm font-normal cursor-pointer text-white bg-transparent"
                                  {...x}
                                ></Link>
                              ))}
                          </div>
                        )}
                      </div>
                    </MenuItem>
                  );
                })}
              </Menu>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

// export default PrimaryMenuLink;
export default React.memo(PrimaryMenuLink);
