import {
  Contentful_Article,
  Contentful_Page,
  Contentful_ResearchArea,
  Contentful_Sector,
  Contentful_StaffMember,
} from 'graphql-types';


export enum TileSize {
  Tile = 'tile',
  Square = 'square',
  Featured = 'featured'
}


export type TileEntry = Contentful_Page
  | Contentful_Article
  | Contentful_Sector
  | Contentful_ResearchArea
  | Contentful_StaffMember
  | any;
