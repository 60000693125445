import React, { useContext, useEffect, useRef, useState } from "react";
import gsap from "gsap";
import SVG from "react-inlinesvg";
import { Contentful_NavigationItem, Contentful_SiteSettings } from "graphql-types";
import Maybe from "graphql/tsutils/Maybe";
import Menu from "./menu";
import PrimaryMenuLink from "./primary-menu-link";
import MenuItem from "./menu-item";
import Logo from "./logo";
import { SiteSettingsContext } from "../contexts/SiteSettingsContext";

interface FloatingMenuProps {
  settings: Contentful_SiteSettings;
  onClickSearchOpen?: any;
  onMenuClose?: any;
}

function FloatingMenu({ settings, onClickSearchOpen, onMenuClose }: FloatingMenuProps) {
  const SiteSettings: Contentful_SiteSettings = useContext(SiteSettingsContext);

  const isOnTransition = useRef(false);
  interface State {
    isMenuOpen: boolean;
    isMenuVisible: boolean;
  }
  const [state, setState] = useState<State>({
    isMenuOpen: true,
    isMenuVisible: false,
  });

  function onClickMenuClose() {
    setState((prevState) => ({
      ...prevState,
      isMenuOpen: false,
    }));
  }

  function resetAnimations(items: any) {
    let animationItems: Array<any> = [];
    for (const property in items) {
      if (items[property].current) {
        animationItems.push(items[property].current);
      }
    }
    gsap.killTweensOf(animationItems);
  }

  const animationMenuRefs: any = {
    containerMenu: useRef(null),
    containerMenuBackground: useRef(null),
    containerMenuLogo: useRef(null),
    containerMenuButtonClose: useRef(null),
    containerMenuButtonSearch: useRef(null),
    containerMenuButtonFeatured: useRef(null),
    containerMenuSearch: useRef(null),
    containerMenuItems: useRef<(HTMLDivElement | null)[]>([]),
  };

  function openMenu() {
    if (!state.isMenuVisible) {
      gsap.set(animationMenuRefs.containerMenu.current, { opacity: 1 });

      gsap.fromTo(
        animationMenuRefs.containerMenuBackground.current,
        { opacity: 1, y: "-100%" },
        { opacity: 1, y: "0%", duration: 0.6, ease: "power3.inOut" }
      );

      gsap.fromTo(
        animationMenuRefs.containerMenuLogo.current,
        { opacity: 0 },
        { opacity: 1, duration: 0.6, delay: 0.2, ease: "power3.out" }
      );
      gsap.fromTo(
        [animationMenuRefs.containerMenuButtonClose.current, animationMenuRefs.containerMenuButtonSearch.current],
        { opacity: 0, scale: 0.6 },
        { opacity: 1, scale: 1, duration: 0.6, delay: 0.2, ease: "power3.out" }
      );

      gsap.fromTo(
        animationMenuRefs.containerMenuButtonFeatured.current,
        { opacity: 0, scale: 0.8 },
        { opacity: 1, scale: 1, duration: 0.6, delay: 0.3, ease: "power3.out" }
      );

      gsap.fromTo(
        animationMenuRefs.containerMenuSearch.current,
        { opacity: 0 },
        { opacity: 1, duration: 0.6, delay: 0.4, ease: "power3.out" }
      );

      gsap.fromTo(
        animationMenuRefs.containerMenuItems.current,
        { opacity: 0 },
        { opacity: 1, duration: 0.6, delay: 0.5, ease: "power3.out", stagger: 0.02 }
      );

      setState((prevState) => ({
        ...prevState,
        isMenuVisible: true,
      }));
    }
  }

  function closeMenu() {
    if (!isOnTransition.current) {
      isOnTransition.current = true;
      if (state.isMenuVisible) {
        gsap.to(animationMenuRefs.containerMenuItems.current, {
          opacity: 0,
          duration: 0.6,
          delay: 0,
          ease: "power3.out",
          stagger: -0.02,
        });
        gsap.to(animationMenuRefs.containerMenuSearch.current, {
          opacity: 0,
          duration: 0.6,
          delay: 0.1,
          ease: "power3.out",
        });
        gsap.to(animationMenuRefs.containerMenuButtonFeatured.current, {
          opacity: 0,
          duration: 0.6,
          delay: 0.2,
          ease: "power3.out",
        });
        gsap.to(animationMenuRefs.containerMenuLogo.current, {
          opacity: 0,
          duration: 0.6,
          delay: 0.3,
          ease: "power3.out",
        });
        gsap.to([animationMenuRefs.containerMenuButtonClose.current, animationMenuRefs.containerMenuButtonSearch.current], {
          opacity: 0,
          scale: 1,
          duration: 0.6,
          delay: 0.3,
          ease: "power3.out",
        });
        gsap.to(animationMenuRefs.containerMenuBackground.current, {
          opacity: 1,
          y: "-100%",
          duration: 0.6,
          delay: 0.2,
          ease: "power3.inOut",
          onComplete: () => {
            if (onMenuClose) {
              onMenuClose();
            }
          },
        });
      }
    }
  }

  function resetMenuAnimations() {
    resetAnimations(animationMenuRefs);
  }

  useEffect(() => {
    if (state.isMenuOpen) {
      openMenu();
    } else {
      closeMenu();
    }
    return () => {};
  });

  return (
    <div
      ref={animationMenuRefs.containerMenu}
      className={`fixed top-0 bottom-0 left-0 right-0 text-white z-50 overflow-auto`}
    >
      <div
        ref={animationMenuRefs.containerMenuBackground}
        className="fixed top-0 left-0 bottom-0 right-0 bg-slate-400"
      ></div>
      <div className="relative pb-8">
        <div className="container mx-auto py-3 px-6">
          <div className="flex justify-between items-center">
            <div ref={animationMenuRefs.containerMenuLogo}>
              <Logo />
            </div>
            <div>
              <div className="relative flex">
                <div ref={animationMenuRefs.containerMenuButtonSearch}>
                  <button onClick={onClickSearchOpen} className="mr-3 flex justify-end items-center w-8 h-8">
                    <SVG src="/images/icons/search.svg" className="w-3 h-3" title="Open search" cacheRequests={true} />
                  </button>
                </div>
                <div ref={animationMenuRefs.containerMenuButtonClose} className="flex justify-center items-center">
                  <button onClick={onClickMenuClose} className="flex justify-end items-center  w-8 h-8">
                    <SVG src="/images/icons/close.svg" className="w-3 h-3" title="Close menu" cacheRequests={true} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div ref={animationMenuRefs.containerMenuButtonFeatured}>
            {SiteSettings.primaryContactPage && SiteSettings.primaryContactPage.urlSlug && (
              <div className="container mx-auto py-3 px-6">
                <a
                  href={SiteSettings.primaryContactPage.urlSlug}
                  className="border-2 border-solid rounded-full w-full block text-center text-base font-bold p-4 select-none"
                >
                  Get in Touch
                </a>
              </div>
            )}
          </div>
          <div ref={animationMenuRefs.containerMenuSearch}>
            {/* <div className="container mx-auto py-3 px-6">
              <form action="">
                <div className="flex items-center">
                  <div className="mr-2">
                    <button type="submit" className="bg-transparent outline-none">
                      <SVG src="/images/icons/search.svg" width="13" height="13" title="Search" cacheRequests={true} />
                    </button>
                  </div>
                  <div className="w-full">
                    <input
                      placeholder="What are you looking for?"
                      className="bg-transparent text-base w-full placeholder-slate-200 text-white text-md outline-none"
                    ></input>
                  </div>
                </div>
              </form>
            </div> */}
          </div>
          {settings.primaryNavigationCollection?.items && (
            <div>
              <Menu>
                {settings.primaryNavigationCollection?.items.map((item: Maybe<Contentful_NavigationItem>, key: number) => {
                  return (
                    <MenuItem key={key} className="cursor-pointer">
                      <div
                        ref={(ref: HTMLDivElement) => {
                          animationMenuRefs.containerMenuItems.current[key] = ref;
                        }}
                      >
                        {state.isMenuVisible && <PrimaryMenuLink item={item}></PrimaryMenuLink>}
                      </div>
                    </MenuItem>
                  );
                })}
              </Menu>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FloatingMenu;
