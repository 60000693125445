import React, { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { Contentful_NavigationItem, Contentful_SiteSettings, Contentful_ContentTypeLocationCollection } from "graphql-types";
import SVG from "react-inlinesvg";
import classnames from "classnames";
import { ThemeContext } from "../contexts/ThemeContext";
import Maybe from "graphql/tsutils/Maybe";
import Menu from "./menu";
import PrimaryNavBarLink from "./primary-navbar-link";
import Logo from "./logo";

interface NavbarProps {
  onClickMenuOpen?: any;
  onClickSearchOpen?: any;
  onLocationOpen?: any;
  settings: Contentful_SiteSettings;
  isNavbarInitiallyTransparent: boolean;
}

interface State {
  isNavbarMouseEnter: boolean;
  isNavbarTransparent: boolean;
  isScrollActive: boolean;
  isNavbarHidden: boolean;
}

function Navbar({
  settings,
  onClickMenuOpen,
  onLocationOpen,
  onClickSearchOpen,
  isNavbarInitiallyTransparent,
}: NavbarProps) {
  const color = useContext(ThemeContext);
  const bgColor = color === "slate" ? `bg-slate-100` : `bg-${color}-100`;
  const textColor = `text-slate-400`;

  const [state, setState] = useState<State>({
    isNavbarMouseEnter: false,
    isNavbarTransparent: isNavbarInitiallyTransparent,
    isScrollActive: true,
    isNavbarHidden: false,
  });
  const prevScrollYRef = useRef(0);
  const animationNavbarRefs: any = {
    containerNavbar: useRef(null),
  };
  let isNavbarRendered: MutableRefObject<boolean> = useRef(false);

  useEffect(() => {
    if (state.isNavbarHidden) {
      gsap.to(animationNavbarRefs.containerNavbar.current, {
        y: "-100%",
        duration: 0.3,
        ease: "power2.inOut",
      });
    } else {
      gsap.to(animationNavbarRefs.containerNavbar.current, {
        y: "0%",
        duration: 0.6,
        ease: "power3.inOut",
      });
    }

    return () => {
      gsap.killTweensOf(animationNavbarRefs.containerNavbar.current);
    };
  }, [state.isNavbarHidden]);

  useEffect(() => {
    if (!isNavbarRendered.current) {
      isNavbarRendered.current = true;
      if (window.scrollY > 20) {
        gsap.set(animationNavbarRefs.containerNavbar.current, {
          y: "-100%",
          duration: 0.3,
          ease: "power2.inOut",
        });
      }
    }
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  });

  function onScroll() {
    if (state.isScrollActive) {
      if (window.scrollY > prevScrollYRef.current && window.scrollY > 20) {
        if (!state.isNavbarHidden) {
          setState((prevState) => ({
            ...prevState,
            isNavbarHidden: true,
          }));
        }
      } else {
        if (window.scrollY < prevScrollYRef.current) {
          setState((prevState) => ({
            ...prevState,
            isNavbarTransparent: window.scrollY < 20 && isNavbarInitiallyTransparent ? true : false,
            isNavbarHidden: false,
          }));
        }
      }

      prevScrollYRef.current = window.scrollY;
    }
  }

  function onMouseEnter() {
    setState((prevState) => ({
      ...prevState,
      isScrollActive: false,
    }));

    if (state.isNavbarTransparent) {
      setState((prevState) => ({
        ...prevState,
        isNavbarMouseEnter: true,
        isNavbarTransparent: false,
      }));
    }
  }

  function onMouseLeave() {
    setState((prevState) => ({
      ...prevState,
      isScrollActive: true,
    }));

    if (window.scrollY < 20 && isNavbarInitiallyTransparent) {
      setState((prevState) => ({
        ...prevState,
        isNavbarMouseEnter: false,
        isNavbarTransparent: true,
      }));
    }
  }

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={animationNavbarRefs.containerNavbar}
      className="fixed left-0 right-0 select-none z-40"
    >
      <div
        className={`relative text-white z-40 transition-colors duration-200 ${state.isNavbarTransparent ? "" : textColor}`}
      >
        <div
          className={`absolute top-0 left-0 right-0 bottom-0 transition-colors duration-200 ${
            state.isNavbarTransparent ? "bg-transparent" : bgColor
          }`}
        ></div>
        <div className="relative container mx-auto py-3 px-6">
          <div className="flex justify-between items-center">
            <Logo />
            <nav className="site-navigation w-full hidden lg:block">
              <div className="px-4 pl-10 w-full">
                <Menu className="font-medium flex w-full justify-start items-stretch">
                  {settings.primaryNavigationCollection?.items.map((item: Maybe<Contentful_NavigationItem>, key: number) => {
                    const itemClasses = classnames({
                      "mr-2": key >= (settings.primaryNavigationCollection?.items.length || 0) - 1 ? false : true,
                    });
                    return (
                      <li key={key} className={itemClasses}>
                        <PrimaryNavBarLink item={item}></PrimaryNavBarLink>
                      </li>
                    );
                  })}
                </Menu>
              </div>
            </nav>
            <button className="block lg:hidden" onClick={onClickMenuOpen}>
              <SVG src="/images/icons/menu.svg" width="30" height="14" title="Open menu" cacheRequests={true} />
            </button>
            <div className={"hidden lg:flex"}>
              <button
                className={`hidden transition-colors lg:flex justify-end items-center duration-200 hover:${textColor}`}
                style={{ width: 30, height: 30 }}
                onClick={onLocationOpen}
              >
                <div>
                  <SVG src="/images/icons/globe.svg" width="13" height="14" title="Location" cacheRequests={true} />
                </div>
              </button>
              <button
                className={`hidden transition-colors lg:flex justify-end items-center duration-200 hover:${textColor}`}
                style={{ width: 30, height: 30 }}
                onClick={onClickSearchOpen}
              >
                <div>
                  <SVG src="/images/icons/search.svg" width="13" height="14" title="Search" cacheRequests={true} />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// export default Navbar;
export default React.memo(Navbar);
