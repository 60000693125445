import React from "react";
import Menu from "../components/menu";
import MenuItem from "../components/menu-item";
import NavLink from "../components/link";
import { Contentful_NavigationItem, Contentful_SiteSettingsFooterNavigationCollection, Maybe } from "graphql-types";

function FooterNav(collection: Contentful_SiteSettingsFooterNavigationCollection) {
  return (
    <>
      <h5 className="sr-only">Navigation</h5>
      <Menu>
        {collection.items &&
          collection.items.map((item: Maybe<Contentful_NavigationItem>, key: number) => {
            if (item)
              return (
                <MenuItem {...item} className={`${key + 1 !== collection.items.length ? "pb-3" : ""}`} key={key}>
                  <NavLink {...item} />
                </MenuItem>
              );
            return null;
          })}
      </Menu>
    </>
  );
}

// export default FooterNav;
export default React.memo(FooterNav);
