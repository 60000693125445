import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import get from "lodash/get";
import { Link } from "gatsby-plugin-intl";
import FooterNav from "../components/footer-nav";
import FooterUsefulLinks from "../components/footer-useful-links";
import FooterSocialNetworkLinks from "../components/footer-social-network-links";
import FooterHygieneLinks from "../components/footer-hygiene-links";
import SubscribeToNewsletter from "../components/footer-newsletter-subscribe-form";
import InlineSVG from "../components/inline-svg";
import { Contentful_Asset, Contentful_SiteSettings } from "graphql-types";

function MinimalLogo(minimalLogo: Contentful_Asset) {
  return (
    <div className="pb-6">
      <Link to="/" className="inline-block">
        <InlineSVG image={minimalLogo} width="50" height="50" />
      </Link>
    </div>
  );
}

function Footer() {
  const data = useStaticQuery(footerQuery);
  const settings: Contentful_SiteSettings = get(data, "contentful.siteSettingsCollection.items.0");

  return (
    <footer className="bg-hero-green text-white text-lg lg:text-xl py-10 lg:pt-20">
      <section className="container mx-auto px-6 md:px-8 lg:px-6">
        <h4 className="sr-only">Footer</h4>
        {settings.minimalLogo && <MinimalLogo {...settings.minimalLogo} />}

        <div className="lg:flex lg:justify-between pb-10 md:pb-20 lg:pb-32">
          {settings.taglineLockup && <InlineSVG image={settings.taglineLockup} className="h-32 md:h-48 mb-10" />}
          <div className="md:grid md:grid-cols-2 md:gap-4 lg:w-1/2">
            <nav className="font-medium mb-10 md:mb-0">
              {settings.footerNavigationCollection?.items && <FooterNav {...settings.footerNavigationCollection} />}
            </nav>
            <div className="md:flex flex-wrap content-between md:pt-1 lg:pt-0">
              <nav className="text-base lg:text-lg w-full">
                {settings.footerUsefulLinksCollection?.items && (
                  <FooterUsefulLinks {...settings.footerUsefulLinksCollection} />
                )}
              </nav>
              <nav className="pt-4 w-full">
                {settings.socialNetworkLinksCollection?.items && (
                  <FooterSocialNetworkLinks {...settings.socialNetworkLinksCollection} />
                )}
              </nav>
            </div>
          </div>
        </div>

        <div className="md:flex md:justify-between">
          {settings.footerNewsletterSubscriptionListId && (
            <SubscribeToNewsletter
              listId={settings.footerNewsletterSubscriptionListId}
              tags={settings.footerNewsletterSubscriptionTags}
            />
          )}
          <div className="md:w-1/2 md:self-end mt-8 md:mt-0">
            <div className="text-xs pb-6 md:p-0">
              &copy; {new Date().getFullYear()} {settings.fullBusinessName}
            </div>
            <nav className="text-xs pt-1 leading-tight">
              {settings.footerHygieneLinksCollection?.items && (
                <FooterHygieneLinks {...settings.footerHygieneLinksCollection} />
              )}
            </nav>
          </div>
        </div>
      </section>
    </footer>
  );
}

const footerQuery = graphql`
  query FooterQuery {
    contentful {
      siteSettingsCollection(limit: 1) {
        items {
          footerNavigationCollection {
            items {
              ...navigationItemFields
            }
          }
          footerUsefulLinksCollection {
            items {
              ...navigationItemFields
            }
          }
          footerHygieneLinksCollection {
            items {
              ...navigationItemFields
            }
          }
          socialNetworkLinksCollection {
            items {
              ...socialNetworkLinkItemFields
            }
          }
          minimalLogo {
            url
            title
          }
          taglineLockup {
            title
            url
          }
          fullBusinessName
          footerNewsletterSubscriptionListId
          footerNewsletterSubscriptionTags
        }
      }
    }
  }
`;

export default Footer;
