import React from "react";
import dayjs from "dayjs";
import { ContentType, Type } from "../contexts/ThemeContext";
import { isDateOnLightSavingsHours } from "../utils/date-utils";

export type BadgeProps = {
  text: ContentType | any;
  date?: any;
};

function BadgeEventDate({ text, date }: BadgeProps) {
  const isCurrentDateOnLightSavingHours: boolean = isDateOnLightSavingsHours(new Date(date));
  const currentDate = dayjs(date).add(!isCurrentDateOnLightSavingHours ? 1 : 0, "hour");
  const dateToText = currentDate.format("DD MMM YYYY");

  return (
    <div className="font-bold text-slate-400 text-sm p-2 px-3 pointer-events-none">
      {text}
      {date && <span> &middot; {dateToText}</span>}
    </div>
  );
}

function Badge({ text, date }: BadgeProps) {
  const colourWay = Type(text);
  const isEvent = text === "Event" ?? false;

  return (
    <div
      className={`transition-colors duration-100 pointer-events-none bg-${colourWay}-${
        colourWay === "slate" ? "400" : "500"
      } rounded-md inline-block overflow-hidden hover:opacity-90`}
    >
      {!isEvent && <div className="font-bold text-white text-sm p-2 px-3 pointer-events-none">{text}</div>}
      {isEvent && <BadgeEventDate text={text} date={date} />}
    </div>
  );
}

// export default Badge;
export default React.memo(Badge);
