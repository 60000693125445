import { pickBy, identity, includes, get, sortBy } from "lodash";
import qs from "qs";
import { FixedObject, FluidObject } from "gatsby-image";
import { Contentful_Asset } from "graphql-types";

type Options = {
  width?: number;
  height?: number;
  jpegProgressive?: boolean;
  quality?: number;
  toFormat?: string;
  resizingBehavior?: string;
  cropFocus?: string;
  background?: string;
};

export interface FixedOptions extends Options {}

export interface FluidOptions extends Options {
  aspectRatio?: number;
  maxWidth: number | undefined;
  maxHeight: number | undefined;
  sizes?: string;
  [k: string]: any;
}

const CONTENTFUL_IMAGE_MAX_SIZE = 4000;

const createUrl = (imgUrl: string, options: Options) => {
  // Convert to Contentful names and filter out undefined/null values.
  const args = pickBy(
    {
      w: options.width,
      h: options.height,
      fl: options.jpegProgressive ? `progressive` : null,
      q: options.quality ? options.quality : 80,
      fm: options.toFormat || ``,
      fit: options.resizingBehavior || ``,
      f: options.cropFocus || ``,
      bg: options.background || ``,
    },
    identity
  );
  return `${imgUrl}?${qs.stringify(args)}`;
};

const isImage = (image: Contentful_Asset) =>
  includes([`image/jpeg`, `image/jpg`, `image/png`, `image/webp`, `image/gif`, `image/svg+xml`], get(image, `contentType`));

const getBasicImageProps = (image: Contentful_Asset, args: Options) => {
  let aspectRatio;
  if (args.width && args.height) {
    aspectRatio = args.width / args.height;
  } else {
    aspectRatio = image.width / image.height;
  }

  return {
    baseUrl: image.url,
    contentType: image.contentType,
    aspectRatio,
    width: image.width,
    height: image.height,
  };
};

export const resolveFixed = (image: Contentful_Asset, options: FixedOptions): FixedObject | null => {
  if (!isImage(image)) return null;

  const { baseUrl, width, aspectRatio } = getBasicImageProps(image, options);

  let desiredAspectRatio = aspectRatio;

  // If no dimension is given, set a default width
  if (options.width === undefined && options.height === undefined) {
    options.width = 400;
  }

  // If only a height is given, calculate the width based on the height and the aspect ratio
  if (options.height !== undefined && options.width === undefined) {
    options.width = Math.round(options.height * desiredAspectRatio);
  }

  // If we're cropping, calculate the specified aspect ratio.
  if (options.width !== undefined && options.height !== undefined) {
    desiredAspectRatio = options.width / options.height;
  }

  // If the user selected a height and width (so cropping) and fit option
  // is not set, we'll set our defaults
  if (options.width !== undefined && options.height !== undefined) {
    if (!options.resizingBehavior) {
      options.resizingBehavior = `fill`;
    }
  }

  // Create sizes (in width) for the image. If the width of the
  // image is 800px, the sizes would then be: 800, 1200, 1600,
  // 2400.
  //
  // This is enough sizes to provide close to the optimal image size for every
  // device size / screen resolution
  let fixedSizes = [];
  fixedSizes.push(options.width);
  fixedSizes.push(options.width * 1.5);
  fixedSizes.push(options.width * 2);
  fixedSizes.push(options.width * 3);
  fixedSizes = fixedSizes.map(Math.round);

  // Filter out sizes larger than the image's width and the contentful image's max size.
  const filteredSizes = fixedSizes.filter((size) => {
    const calculatedHeight = Math.round(size / desiredAspectRatio);
    return size <= CONTENTFUL_IMAGE_MAX_SIZE && calculatedHeight <= CONTENTFUL_IMAGE_MAX_SIZE && size <= width;
  });

  // Sort sizes for prettiness.
  const sortedSizes = sortBy(filteredSizes);

  // Create the srcSet.
  const srcSet = sortedSizes
    .map((size, i) => {
      let resolution;
      switch (i) {
        case 0:
          resolution = `1x`;
          break;
        case 1:
          resolution = `1.5x`;
          break;
        case 2:
          resolution = `2x`;
          break;
        case 3:
          resolution = `3x`;
          break;
        default:
      }
      const h = Math.round(size / desiredAspectRatio);
      return `${createUrl(baseUrl, {
        ...options,
        width: size,
        height: h,
      })} ${resolution}`;
    })
    .join(`,\n`);

  let pickedHeight, pickedWidth;
  if (options.height) {
    pickedHeight = options.height;
    pickedWidth = options.height * desiredAspectRatio;
  } else {
    pickedHeight = options.width / desiredAspectRatio;
    pickedWidth = options.width;
  }

  return {
    width: Math.round(pickedWidth),
    height: Math.round(pickedHeight),
    src: createUrl(baseUrl, {
      ...options,
      width: options.width,
    }),
    srcSet,
  };
};

export const resolveFluid = (image: Contentful_Asset, options: FluidOptions): FluidObject | null => {
  if (!isImage(image)) return null;

  const { baseUrl, width, aspectRatio } = getBasicImageProps(image, options);

  let desiredAspectRatio = aspectRatio;

  // If no dimension is given, set a default maxWidth
  if (options.maxWidth === undefined && options.maxHeight === undefined) {
    options.maxWidth = 800;
  }

  // If only a maxHeight is given, calculate the maxWidth based on the height and the aspect ratio
  if (options.maxHeight !== undefined && options.maxWidth === undefined) {
    options.maxWidth = Math.round(options.maxHeight * desiredAspectRatio);
  }

  // If we're cropping, calculate the specified aspect ratio.
  if (options.maxHeight !== undefined && options.maxWidth !== undefined) {
    desiredAspectRatio = options.maxWidth / options.maxHeight;
  }

  // If the users didn't set a default sizes, we'll make one.
  if (!options.sizes) {
    options.sizes = `(max-width: ${options.maxWidth}px) 100vw, ${options.maxWidth}px`;
  }

  // Create sizes (in width) for the image. If the max width of the container
  // for the rendered markdown file is 800px, the sizes would then be: 200,
  // 400, 800, 1200, 1600, 2400.
  //
  // This is enough sizes to provide close to the optimal image size for every
  // device size / screen resolution
  let fluidSizes = [];
  fluidSizes.push(options.maxWidth / 4);
  fluidSizes.push(options.maxWidth / 2);
  fluidSizes.push(options.maxWidth);
  fluidSizes.push(options.maxWidth * 1.5);
  fluidSizes.push(options.maxWidth * 2);
  fluidSizes.push(options.maxWidth * 3);
  fluidSizes = fluidSizes.map(Math.round);

  // Filter out sizes larger than the image's maxWidth and the contentful image's max size.
  const filteredSizes = fluidSizes.filter((size) => {
    const calculatedHeight = Math.round(size / desiredAspectRatio);
    return size <= CONTENTFUL_IMAGE_MAX_SIZE && calculatedHeight <= CONTENTFUL_IMAGE_MAX_SIZE && size <= width;
  });

  // Add the original image (if it isn't already in there) to ensure the largest image possible
  // is available for small images.
  if (
    !filteredSizes.includes(width) &&
    width < CONTENTFUL_IMAGE_MAX_SIZE &&
    Math.round(width / desiredAspectRatio) < CONTENTFUL_IMAGE_MAX_SIZE
  ) {
    filteredSizes.push(width);
  }

  // Sort sizes for prettiness.
  const sortedSizes = sortBy(filteredSizes);

  //if aspect ratio was specified please override it

  if (options.aspectRatio) {
    desiredAspectRatio = options.aspectRatio;
  }

  // Create the srcSet.
  const srcSet = sortedSizes
    .map((width) => {
      const h = Math.round(width / desiredAspectRatio);
      return `${createUrl(image.url, {
        ...options,
        width,
        height: h,
      })} ${Math.round(width)}w`;
    })
    .join(`,\n`);

  return {
    aspectRatio: desiredAspectRatio,
    src: createUrl(baseUrl, {
      ...options,
      width: options.maxWidth,
      height: options.maxHeight,
    }),
    srcSet,
    sizes: options.sizes,
  };
};
