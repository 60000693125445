import React from "react";
import Cookies from "js-cookie";
import { changeLocale, useIntl } from "gatsby-plugin-intl";
import { setLocation } from "../utils/geo-location";
import { SUPPORTED_REGIONS } from "../utils/constants";

type ModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export function Modal({ isOpen, setIsOpen }: ModalProps) {
  const { locale } = useIntl();
  const currentLocation = Cookies.get("location");

  if (!currentLocation) {
    setIsOpen(false);
  }

  const handleCancel = () => {
    changeLocale(currentLocation);
    setIsOpen(false);
  };

  const updateRegion = () => {
    setLocation(locale).then(() => {
      setIsOpen(false);
      window.location.reload();
    });
  };

  const ModalButton = ({
    label,
    variant = "primary",
    onClick,
  }: {
    label: string;
    variant?: "primary" | "secondary";
    onClick?: () => void;
  }) => (
    <button
      onClick={onClick}
      className={`flex h-14 py-0 px-6 rounded-full border-2 items-center justify-center font-semibold ${
        variant === "primary" ? "bg-slate-400 border-slate-400 text-white" : "bg-white text-slate-300 border-slate-300"
      }`}
    >
      {label}
    </button>
  );

  const currentRegion = SUPPORTED_REGIONS.find((region) => region.code === currentLocation)?.label;
  const intendedRegion = SUPPORTED_REGIONS.find((region) => region.code === locale)?.label;

  return (
    <>
      <div className={"absolute inset-0 bg-white opacity-80 backdrop-blur z-50"}></div>
      <div className="absolute inset-0 flex gap-4 items-center justify-center modal-wrapper">
        <div className={"modal grid gap-10 shadow"}>
          <div className={"grid grid-flow-col gap-5"}>
            <div className={"grid gap-6 relative"}>
              <p className={"modal-title"}>
                <span>
                  You’re viewing content for <span className={"italic"}>{intendedRegion}</span>.
                </span>
                <br />

                <span className={"text-lg"}>
                  It looks like you’re in <span className={"italic"}>{currentRegion}</span>.
                </span>
              </p>
              <p className={"modal-text"}>
                Would you like to:
                <br />
                <ul>
                  <li>
                    - Switch to the <span className={"font-bold"}>{currentRegion}</span> site for more relevant information?
                  </li>
                  <li>
                    - Continue browsing the <span className={"font-bold"}>{intendedRegion}</span> site?
                  </li>
                </ul>
              </p>
              <button className={"absolute top-0 right-0"} onClick={handleCancel}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                    fill="#3E5D58"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className={"flex flex-col lg:flex-row gap-4"}>
            <ModalButton label={`Switch to ${currentRegion}`} variant={"secondary"} onClick={handleCancel} />
            <ModalButton label={`Continue on ${intendedRegion}`} onClick={updateRegion} />
          </div>
        </div>
      </div>
    </>
  );
}
