import React from "react";
import classNames from "classnames";

interface MenuItemProps {
  children?: any;
  label?: string | null | undefined;
  className?: string;
  onMouseEnter?: any;
  onMouseLeave?: any;
  [k: string]: any;
}

function MenuItem({ children, ...props }: MenuItemProps) {
  return (
    <li className={classNames(props.className)} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
      {children}
    </li>
  );
}

// export default MenuItem;
export default React.memo(MenuItem);
