import React from "react";
import parse from "html-react-parser";

export const textToSuperscript = (t: any) => {
  return parse(t.replace(/[™®©]/g, "<sup>$&</sup>"));
};

export const textRender = (t: any) => {
  let text: any = t;
  text = textToSuperscript(text);
  return text;
};

export const textRenderOptions = {
  renderText: (text: string) => {
    return text.split("\n").map((t, i) =>
      i > 0 ? (
        <React.Fragment key={i}>
          <br />
          {textRender(t)}
        </React.Fragment>
      ) : (
        textRender(t)
      )
    );
  },
};
