import { Contentful_Asset, Maybe } from "graphql-types";

const contentfulImageHelper = (image: Maybe<Contentful_Asset> | undefined) => {
  // Return standard share size for meta tags like og:image
  const getShareUrl = () => {
    if (!image || !image.url) return null;

    return `${image.url}?w=1200&h=630&fit=fill&q=80&fm=JPG`;
  };

  return {
    getShareUrl,
  };
};

export default contentfulImageHelper;
