import Cookies from "js-cookie";
import { SUPPORTED_REGIONS } from "./constants";

export async function getCountryCodeAPI({ defaultLocale }: { defaultLocale: string }) {
  try {
    const response = await fetch(`https://api.ipregistry.co/?key=${process.env.IP_REGISTRY_API_KEY}`);
    const data = await response.json();

    const country = data?.location?.country?.code;
    const lang = data?.location?.language?.code;
    const codeFormatted = (lang ? `${lang}-${country}` : country).toLowerCase();

    return SUPPORTED_REGIONS.some((region) => region.code === codeFormatted) ? codeFormatted : defaultLocale;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
}

export async function setLocation(code: string) {
  Cookies.set("location", code, { expires: 7 });
}
