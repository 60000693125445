import React from "react";
import { Link } from "gatsby-plugin-intl";
import { Contentful_Article, Contentful_NavigationItemHighlight } from "graphql-types";
import Badge from "./badge";
import Image from "./image";
import { getUrl } from "./tile/entry";

function PrimaryNavbarHighlight(highlightItem: Contentful_NavigationItemHighlight) {
  if (!highlightItem || !highlightItem.cardImage) return null;

  const url = getUrl(highlightItem);

  return (
    <div>
      <div className="relative rounded-3xl overflow-hidden">
        <Link to={`${url}`} title={highlightItem.title ?? ""} className="relative">
          <Image image={highlightItem.cardImage} alt={highlightItem.title ?? ""} type="fixed" />
        </Link>
        <div
          className="absolute pointer-events-none"
          style={{
            top: 20,
            left: 20,
          }}
        >
          <div className="relative">
            {(highlightItem as Contentful_Article).contentType?.singularName && (
              <Badge text={(highlightItem as Contentful_Article).contentType?.singularName} />
            )}
          </div>
        </div>
      </div>
      {highlightItem.title && (
        <Link to={`${url}`} title={highlightItem.title ?? ""} className="relative text-lg leading-tight font-medium mt-4">
          {highlightItem.title}
        </Link>
      )}
    </div>
  );
}

// export default PrimaryNavbarHighlight;
export default React.memo(PrimaryNavbarHighlight);
