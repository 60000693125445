import React from "react";
import classNames from "classnames";

interface MenuProps {
  children?: any;
  className?: string;
}

function Menu({ ...props }: MenuProps) {
  return <ul className={classNames(props.className)}>{props.children}</ul>;
}

// export default Menu;
export default React.memo(Menu);
