import { Contentful_Asset } from "graphql-types";
import React from "react";
import SVG from "react-inlinesvg";

interface Props {
  image: Contentful_Asset;
  height?: string;
  width?: string;
  className?: string;
  style?: any;
}

function InlineSVG({ image, ...props }: Props) {
  if (image && image.url)
    return (
      <SVG
        src={image.url}
        alt={image.title ? image.title : ""}
        title={image.title ? image.title : ""}
        {...props}
        cacheRequests={true}
      />
    );

  return null;
}

// export default InlineSVG;
export default React.memo(InlineSVG);
