import gsap from "gsap/all";
import React, { useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import { useIntl } from "gatsby-plugin-intl";
import Logo from "./logo";

interface SearchProps {
  onClickSearchClose: any;
}

interface State {
  isOpen: boolean;
}

function Search({ onClickSearchClose }: SearchProps) {
  const intl = useIntl();

  const animationRefs: any = {
    onTransition: useRef(false),
    container: useRef(null),
    background: useRef(null),
    content: useRef(null),
    title: useRef(null),
    form: useRef(null),
    field: useRef(null),
    buttonClose: useRef(null),
  };

  const open = () => {
    animationRefs.onTransition.current = true;

    let scaleFrom = 2;
    if (window.innerWidth < 768) {
      scaleFrom = 1.5;
    }

    gsap.set(animationRefs.form.current, { opacity: 0, pointerEvents: "none" });
    gsap.set(animationRefs.buttonClose.current, { opacity: 0, pointerEvents: "none" });

    gsap.fromTo(
      animationRefs.background.current,
      { x: "100%", opacity: 1 },
      { x: "0%", opacity: 1, duration: 1, ease: "power3.out" }
    );
    gsap.fromTo(
      animationRefs.content.current,
      { opacity: 0 },
      { opacity: 1, duration: 0.6, ease: "power3.out", delay: 0.8 }
    );

    gsap.fromTo(
      animationRefs.title.current,
      { scale: scaleFrom, transformOrigin: "top left" },
      { scale: 1, transformOrigin: "top left", duration: 1, ease: "power3.inOut", delay: 1.4 }
    );

    gsap.fromTo(
      animationRefs.form.current,
      { opacity: 0, y: 100 },
      { opacity: 1, y: 0, duration: 1, ease: "power3.inOut", delay: 1.4 }
    );

    gsap.fromTo(
      animationRefs.buttonClose.current,
      { opacity: 0 },
      { opacity: 1, duration: 0.6, ease: "power3.inOut", delay: 1.8, onComplete: onOpen }
    );
  };

  const onOpen = () => {
    animationRefs.field.current.focus();
    gsap.set(animationRefs.form.current, { pointerEvents: "auto" });
    gsap.set(animationRefs.buttonClose.current, { pointerEvents: "auto" });
    animationRefs.onTransition.current = false;
  };

  const close = () => {
    animationRefs.onTransition.current = true;
    gsap.to(animationRefs.content.current, { opacity: 0, duration: 0.6, ease: "power3.out" });
    gsap.to(animationRefs.background.current, {
      x: "-100%",
      opacity: 1,
      duration: 1,
      delay: 0.6,
      ease: "power3.out",
      onComplete: onClose,
    });
  };

  const onClose = () => {
    animationRefs.onTransition.current = false;
    onClickSearchClose();
  };

  const onClickButtonClose = () => {
    if (!animationRefs.onTransition.current) {
      close();
    }
  };

  useEffect(() => {
    open();
  }, []);

  return (
    <div ref={animationRefs.container} className="fixed inset-0 text-white font-body" style={{ zIndex: 9999 }}>
      <div ref={animationRefs.background} className="absolute inset-0 bg-slate-400 opacity-0"></div>
      <div ref={animationRefs.content} className="absolute inset-0 opacity-0">
        <div className="absolute inset-0 flex justify-center items-start mt-24 md:mt-0 md:items-center">
          <div className="container mx-auto px-6">
            <div>
              <p ref={animationRefs.title} className="font-medium leading-tight mb-8 text-2xl">
                Hello, what are
                <br />
                you looking for?
              </p>
            </div>
            <div>
              <form
                action={`/${intl.locale}/search`}
                ref={animationRefs.form}
              >
                <input
                  ref={animationRefs.field}
                  type="text"
                  name="q"
                  placeholder="Type here..."
                  className="w-full bg-transparent text-5xl md:text-7xl border-0 focus:ring focus:ring-transparent placeholder-white p-0 mb-6"
                />
                <button
                  type="submit"
                  className="inline-flex transition-colors duration-100 leading-tight rounded-full p-6 px-12 text-sm font-medium text-white bg-green-400 hover:bg-green-500"
                >
                  Search
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="absolute left-0 right-0">
          <div className="container mx-auto py-3 px-6">
            <div className="flex justify-between items-center">
              <div>
                <div className="md:hidden">
                  <Logo />
                </div>
              </div>
              <div>
                <div className="relative flex">
                  <div className="flex justify-center items-center">
                    <button
                      ref={animationRefs.buttonClose}
                      className="flex justify-end items-center w-8 h-8 md:w-16 md:h-16 md:bg-white md:justify-center md:rounded-full"
                      onClick={onClickButtonClose}
                    >
                      <SVG
                        src="/images/icons/close.svg"
                        className="w-3 h-3 text-white md:w-5 md:h-5 md:text-slate-400"
                        title="Close menu"
                        cacheRequests={true}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Search;
