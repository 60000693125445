import React from "react";
import Tile, { TileProps } from "./index";
import { TileEntry, TileSize } from "./types";

type Props = {
  entry: TileEntry;
  isHighlighted?: boolean;
  size?: TileSize;
  count: number;
};

export function getUrl(entry: TileEntry) {
  switch (entry.__typename) {
    case "ContentfulArticle":
      return `/article/${entry.urlSlug}`;
    case "Contentful_Article":
      return `/article/${entry.urlSlug}`;
    case "Contentful_Page":
      return `/${entry.urlSlug}`;
    case "ContentfulPage":
      return `/${entry.urlSlug}`;
    case "Contentful_Sector":
      return `/${entry.landingPage?.urlSlug}`;
    case "ContentfulSector":
      return `/${entry.landingPage?.urlSlug}`;
    case "ContentfulResearchArea":
      return `/${entry.landingPage?.urlSlug}`;
    case "Contentful_ResearchArea":
      return `/${entry.landingPage?.urlSlug}`;
    case "Contentful_StaffMember":
      return `/staff/${entry.urlSlug}`;
    case "ContentfulStaffMember":
      return `/staff/${entry.urlSlug}`;
    default:
      return "";
  }
}

/**
 * This component receives an entry to create a tile
 *
 */
function TileForEntry(props: Props) {
  const { entry, ...otherProps } = props;
  if (!entry) {
    return null;
  }
  const url = getUrl(entry);
  const date = entry.publishDate || null;

  // Contentful_Page && Contentful_Article uses title
  // Contentful_Sector && Contentful_ResearchArea uses label
  const title = entry.title || entry.label;
  const descript = entry.descript || null;

  // Only add badget if entry is Article type
  let badge =
    (entry.__typename === "Contentful_Article" && {
      text: entry.contentType?.singularName,
      date: entry.eventDate,
    }) ||
    null;

  return (
    <Tile
      {...otherProps}
      image={entry.cardImage}
      title={title}
      descript={descript}
      url={url}
      badge={badge}
      date={date}
      intlLink={true}
    />
  );
}

export default TileForEntry;
