import React, { useContext, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { navigate } from "gatsby-plugin-intl";
import { Helmet } from "react-helmet";
import get from "lodash/get";
import { isIE } from "react-device-detect";
import {
  Contentful_ContentTypeLocationCollection,
  Contentful_DepartmentCollection,
  Contentful_ResearchAreaCollection,
  Contentful_SectorCollection,
  Contentful_SiteSettings,
  Maybe,
} from "graphql-types";
import parse from "html-react-parser";
import { ThemeContext } from "../contexts/ThemeContext";
import { SiteSettingsContext } from "../contexts/SiteSettingsContext";
import { TagsContext } from "../contexts/TagsContext";
import Header from "../components/header";
import Footer from "../components/footer";
import contentfulImageHelper from "../utils/contentful-image-helper";
import { Modal } from "../components/modal";
import { useLocation } from "../contexts/LocationContext";

interface Props {
  title: Maybe<string> | undefined;
  metaAuthor?: Maybe<string> | undefined;
  metaDescription: Maybe<string> | undefined;
  metaImageUrl: Maybe<string> | undefined;
  children: React.ReactNode;
  headSnippet?: Maybe<string> | undefined;
  type: "website" | "article";
  hasHero: boolean;
  noIndex?: boolean;
}

const Layout = (props: Props) => {
  const { locale, currentLocation } = useLocation();

  const colour = useContext(ThemeContext);
  const [isOpen, setIsOpen] = React.useState(false);
  let { contentful } = useStaticQuery(layoutQuery);

  // Internet Explorer, early check and redirect.
  if (isIE) {
    navigate("/unsupported-browser");
    return null;
  }

  const SiteSettings: Contentful_SiteSettings = get(contentful, "siteSettingsCollection.items.0");
  const LocationsCollection: Contentful_ContentTypeLocationCollection["items"] = get(
    contentful,
    "contentTypeLocationCollection.items"
  );
  const SectorCollection: Contentful_SectorCollection = get(contentful, "sectorCollection");
  const ResearchAreaCollection: Contentful_ResearchAreaCollection = get(contentful, "researchAreaCollection");
  const DepartmentCollection: Contentful_DepartmentCollection = get(contentful, "departmentCollection");
  let TagsData = {
    sectorCollection: SectorCollection,
    researchAreaCollection: ResearchAreaCollection,
    departmentCollection: DepartmentCollection,
  };

  const title = `${props.title ? `${props.title} · ` : ""}${SiteSettings.title}`;
  const description = (props.metaDescription && props.metaDescription) || SiteSettings.fallbackMetaDescription || "";
  const url = typeof window !== "undefined" ? window.location.origin + window.location.pathname : "";
  const metaImageUrl = props.metaImageUrl
    ? props.metaImageUrl
    : contentfulImageHelper(SiteSettings.fallbackShareImage).getShareUrl();
  const metaAuthor = props.metaAuthor ?? undefined;

  useEffect(() => {
    if (currentLocation && locale) {
      setIsOpen(currentLocation !== locale);
    }
  }, [locale, currentLocation]);

  return (
    <SiteSettingsContext.Provider value={SiteSettings}>
      <Helmet htmlAttributes={{ lang: locale }} encodeSpecialCharacters={false}>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <link rel="icon" href="/favicon.svg" />
        {metaImageUrl && <meta property="og:image" content={metaImageUrl} />}
        {metaAuthor && <meta name="author" content={metaAuthor} />}
        <meta property="og:type" content={props.type} />
        <meta property="og:url" content={url} />
        <meta property="og:locale" content={locale} />
        {SiteSettings.shortBusinessName && <meta property="og:site_name" content={SiteSettings.shortBusinessName} />}
        <meta property="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@plantandfood" />
        {props.noIndex && <meta name="robots" content="noindex" />}

        <link rel="preconnect" href="https://images.ctfassets.net" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        <link rel="preconnect" href="https://player.vimeo.com" />
        <link rel="preconnect" href="https://www.youtube.com" />
        <meta name="theme-color" content="#3E5D58" />
        {props.headSnippet && typeof props.headSnippet === "string" && parse(props.headSnippet)}
      </Helmet>
      <div className={`bg-${colour}-100 text-${colour}-400 font-body antialiased`} style={{ minWidth: "320px" }}>
        <Header isNavbarInitiallyTransparent={props.hasHero} locations={LocationsCollection} />
        <TagsContext.Provider value={TagsData}>{props.children}</TagsContext.Provider>
        <Footer />
        {isOpen && <Modal isOpen={isOpen} setIsOpen={setIsOpen} />}
      </div>
    </SiteSettingsContext.Provider>
  );
};

export default Layout;

Layout.defaultProps = {
  type: "website",
  hasHero: false,
};

const allNavigationQuery = graphql`
  query navigationQuery {
    contentful {
      navigationItemCollection(limit: 10) {
        items {
          ...navigationItemFields
          childrenCollection(limit: 25) {
            items {
              ...navigationItemFields
              childrenCollection(limit: 20) {
                items {
                  ...navigationItemFields
                }
              }
            }
          }
        }
      }
    }
  }
`;

const layoutQuery = graphql`
  query LayoutQuery {
    contentful {
      siteSettingsCollection(limit: 1) {
        items {
          title
          fallbackMetaDescription
          fallbackShareImage {
            url
          }
          logo {
            url
            title
          }
          minimalLogo {
            url
            title
          }
          contactPhone
          fullBusinessName
          globalSearchEnabled
          shortBusinessName
          primaryCollection {
            urlSlug
          }
          primaryContactPage {
            urlSlug
          }
          applePodcastUrl
          spotifyPodcastUrl
          marketingCreditMessage {
            json
          }
          primaryNavigationCollection(limit: 10) {
            items {
              ...navigationItemFields
              childrenCollection(limit: 25) {
                items {
                  ...navigationItemFields
                  childrenCollection(limit: 20) {
                    items {
                      ...navigationItemFields
                    }
                  }
                }
              }

              blurb
              highlight {
                __typename
                ... on Contentful_Article {
                  sys {
                    id
                    publishedVersion
                  }
                  title
                  urlSlug
                  cardImage {
                    title
                    url
                    height
                    width
                    contentType
                  }
                  contentType {
                    sys {
                      id
                      publishedVersion
                    }
                    name
                    singularName
                  }
                }
                ... on Contentful_Page {
                  sys {
                    id
                    publishedVersion
                  }
                  title
                  urlSlug
                  cardImage {
                    title
                    url
                    height
                    width
                    contentType
                  }
                }
              }
            }
          }
          orderFilterContentTypeCollection {
            items {
              name
            }
          }
          orderFilterResearchAreaCollection {
            items {
              label
            }
          }
          orderFilterSectorCollection {
            items {
              label
            }
          }
          orderFilterDepartmentCollection {
            items {
              label
            }
          }
        }
      }
      sectorCollection(order: label_ASC, limit: 50) {
        items {
          __typename
          sys {
            id
            publishedVersion
          }
          label
          landingPage {
            urlSlug
          }
          cardImage {
            sys {
              id
              spaceId
              environmentId
              publishedVersion
            }
            url
            title
            height
            width
            contentType
          }
          linkedFrom {
            sectorCollection {
              items {
                sys {
                  id
                  publishedVersion
                }
              }
            }
          }
          childrenCollection(limit: 50) {
            items {
              sys {
                id
                publishedVersion
              }
              label
              landingPage {
                urlSlug
              }
            }
          }
        }
      }
      researchAreaCollection(order: label_ASC, limit: 50) {
        items {
          __typename
          sys {
            id
            publishedVersion
          }
          label
          landingPage {
            urlSlug
          }
          cardImage {
            sys {
              id
              spaceId
              environmentId
              publishedVersion
            }
            url
            title
            height
            width
            contentType
          }
          linkedFrom {
            researchAreaCollection {
              items {
                sys {
                  id
                  publishedVersion
                }
              }
            }
          }
          childrenCollection(limit: 50) {
            items {
              sys {
                id
                publishedVersion
              }
              label
              landingPage {
                urlSlug
              }
            }
          }
        }
      }
      departmentCollection(order: label_ASC, limit: 50) {
        items {
          __typename
          sys {
            id
            publishedVersion
          }
          label
          linkedFrom {
            departmentCollection {
              items {
                sys {
                  id
                  publishedVersion
                }
              }
            }
          }
          childrenCollection(limit: 50) {
            items {
              sys {
                id
                publishedVersion
              }
              label
            }
          }
        }
      }
      contentTypeLocationCollection {
        items {
          location
          code
        }
      }
    }
  }
`;
