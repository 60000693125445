import { Contentful_NavigationItem } from "graphql-types";
import Maybe from "graphql/tsutils/Maybe";
import React, { useEffect, useRef, useState } from "react";
import Link from "./link";
import classnames from "classnames";
import gsap from "gsap";
import Menu from "./menu";
import PrimaryNavbarHighlight from "./primary-navbar-highlight";
import MenuItem from "./menu-item";
import { isTouch } from "../utils/device-utils";

function PrimaryNavBarLink({ item }: { item: Maybe<Contentful_NavigationItem> }) {
  interface State {
    isSecondaryMenuOpen: boolean;
    isSecondaryMenuVisible: boolean;
    isChildActive: boolean;
  }
  const [state, setState] = useState<State>({
    isSecondaryMenuOpen: false,
    isSecondaryMenuVisible: false,
    isChildActive: false,
  });

  const isSSR = typeof window === "undefined";
  const isTouchScreen = isTouch();
  const disableInterval: any = useRef(0);
  const currentLink: any = useRef(null);
  const touchClasses = ["bg-slate-400"];

  const subnavClassName = classnames("absolute left-1/2 transform -translate-x-1/2 w-screen right-0 pt-3", {
    hidden: !state.isSecondaryMenuVisible,
  });

  const isSecondaryMenuAvailable: boolean = item?.childrenCollection?.items.length ? true : false;

  function isNavLinkActive(target: any): any {
    return target.classList.contains("bg-slate-300");
  }

  function resetAnimations(items: any) {
    let animationItems: Array<any> = [];
    for (const property in items) {
      if (items[property].current) {
        animationItems.push(items[property].current);
      }
    }
    gsap.killTweensOf(animationItems);
  }

  const animationRefs: any = {
    containerSubMenu: useRef(null),
    containerSubMenuBackground: useRef(null),
    containerSubMenuTitle: useRef(null),
    containerSubMenuItems: useRef<(HTMLDivElement | null)[]>([]),
    containerSubMenuHighlight: useRef(null),
    containerSubMenuLine: useRef(null),
  };

  function openSubMenu() {
    gsap.fromTo(
      animationRefs.containerSubMenuBackground.current,
      { y: "-100%" },
      { y: "0%", duration: 0.6, ease: "power3.inOut" }
    );

    gsap.fromTo(
      animationRefs.containerSubMenuTitle.current,
      { opacity: 0 },
      { opacity: 1, duration: 0.6, delay: 0.3, ease: "power3.out" }
    );

    gsap.fromTo(
      animationRefs.containerSubMenuItems.current,
      { opacity: 0 },
      { opacity: 1, duration: 0.6, delay: 0.4, ease: "power3.out", stagger: 0.02 }
    );

    gsap.fromTo(
      animationRefs.containerSubMenuHighlight.current,
      { opacity: 0 },
      { opacity: 1, duration: 0.6, delay: 0.5, ease: "power3.out", stagger: 0.02 }
    );

    gsap.fromTo(
      animationRefs.containerSubMenuLine.current,
      { opacity: 0 },
      { opacity: 0.2, duration: 0.6, delay: 0.5, ease: "power3.out" }
    );

    setState((prevState) => ({
      ...prevState,
      isSecondaryMenuVisible: true,
    }));
  }

  function closeSubMenu() {
    setState((prevState) => ({
      ...prevState,
      isSecondaryMenuVisible: false,
    }));
    animationRefs.containerSubMenuItems.current = [];
  }

  function resetSubMenuAnimations() {
    resetAnimations(animationRefs);
  }

  useEffect(() => {
    if (state.isSecondaryMenuOpen) {
      openSubMenu();
    } else {
      closeSubMenu();
    }
    return () => {
      resetSubMenuAnimations();
    };
  }, [state.isSecondaryMenuOpen]);

  function onMouseEnter() {
    if (isTouchScreen) {
      return;
    }
    if (isSecondaryMenuAvailable) {
      setState((prevState) => ({
        ...prevState,
        isSecondaryMenuOpen: true,
      }));
    }
  }

  function onMouseLeave() {
    if (isTouchScreen) {
      onBlur();
      return;
    }
    if (isSecondaryMenuAvailable) {
      setState((prevState) => ({
        ...prevState,
        isSecondaryMenuOpen: false,
      }));
    }
  }

  function onTouchScroll(event: any) {
    window.removeEventListener("scroll", onTouchScroll);
    if (isTouchScreen && isSecondaryMenuAvailable) {
      const currentTarget: any = currentLink.current;
      if (currentTarget) {
        currentTarget.classList.remove(...touchClasses);
        if (!isNavLinkActive(currentTarget)) {
          currentTarget.classList.remove("text-white");
        }
      }
      setState((prevState) => ({
        ...prevState,
        isSecondaryMenuOpen: false,
      }));
    }
  }

  function onClickLink(event: any) {
    const currentTarget = event.currentTarget;
    if (isTouchScreen) {
      if (isSecondaryMenuAvailable) {
        if (!state.isSecondaryMenuOpen) {
          window.addEventListener("scroll", onTouchScroll);
          event.preventDefault();
          setState((prevState) => ({
            ...prevState,
            isSecondaryMenuOpen: true,
          }));
          currentLink.current = currentTarget;
          currentTarget.classList.add(...touchClasses);
          if (!isNavLinkActive(currentTarget)) {
            currentTarget.classList.add("text-white");
          }
        } else {
          if (currentTarget.title == item?.label) {
            setState((prevState) => ({
              ...prevState,
              isSecondaryMenuOpen: false,
            }));
            currentTarget.classList.remove(...touchClasses);
            if (!isNavLinkActive(currentTarget)) {
              currentTarget.classList.remove("text-white");
            }
          }
        }
      }
    }
  }

  function onBlur() {
    if (isTouchScreen) {
      clearTimeout(disableInterval.current);
      disableInterval.current = setTimeout(() => {
        if (isTouchScreen && isSecondaryMenuAvailable) {
          const currentTarget: any = currentLink.current;
          if (currentTarget) {
            currentTarget.classList.remove(...touchClasses);
            if (!isNavLinkActive(currentTarget)) {
              currentTarget.classList.remove("text-white");
            }
          }
          setState((prevState) => ({
            ...prevState,
            isSecondaryMenuOpen: false,
          }));
        }
      }, 100);
    }
  }

  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", onTouchScroll);
    };
  }, []);

  return (
    <div className="h-full" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Link
        {...item}
        className={classnames(
          "flex items-center p-5 rounded-full h-full text-center text-sm  font-medium transition-colors duration-200 leading-tight ease-in-out",
          {
            "hover:bg-slate-400 hover:text-white": !isTouchScreen,
            "bg-slate-300 text-white": state.isChildActive,
          }
        )}
        activeClassName="bg-slate-300 text-white"
        onClick={onClickLink}
      >
        {item?.label}
      </Link>
      <div className={subnavClassName}>
        {isSecondaryMenuAvailable && (
          <div
            ref={animationRefs.containerSubMenu}
            className={`relative text-white z-30 overflow-hidden ${state.isSecondaryMenuVisible ? "block" : "hidden"}`}
          >
            <div
              ref={animationRefs.containerSubMenuBackground}
              className="absolute top-0 right-0 bottom-0 left-0 bg-slate-400"
            ></div>
            <div ref={animationRefs.containerSubMenuLine} className="absolute bottom-0 bg-black w-full h-px opacity-0"></div>
            <div className="absolute top-0 right-0 bottom-0 left-0"></div>
            <div className="relative">
              <div className="container mx-auto pt-10 px-12 xl:pl-14">
                <Link
                  {...item}
                  className={classnames("text-3xl mb-0 font-medium rounded-full p-3 px-6 bg-transparent inline-block")}
                  activeClassName="bg-slate-300 text-white"
                >
                  <span ref={animationRefs.containerSubMenuTitle}>{item?.label}</span>
                </Link>
              </div>
              <div className="container mx-auto flex justify-between py-10 px-12 pt-8 xl:pl-14">
                <div className="flex-grow mr-10">
                  <Menu className="grid grid-cols-3 gap-4 mb-6 gap-y-12">
                    {item?.childrenCollection?.items &&
                      item?.childrenCollection?.items.map((item: Maybe<Contentful_NavigationItem>, key: number) => {
                        if (item)
                          if (!state.isChildActive && !isSSR && location.pathname.endsWith(item.internalPage?.urlSlug ?? ""))
                            setState((prevState) => ({
                              ...prevState,
                              isChildActive: true,
                            }));

                        return (
                          <MenuItem key={key} className="">
                            <div
                              ref={(ref: HTMLDivElement) => {
                                animationRefs.containerSubMenuItems.current[key] = ref;
                              }}
                            >
                              <Link
                                {...item}
                                className="inline-flex transition-colors duration-100 leading-tight rounded-full p-3 px-6 text-lg font-semibold cursor-pointer text-white bg-transparent hover:bg-slate-300"
                              />

                              {item?.childrenCollection.items.filter((x) => x).length > 0 && (
                                <div className="px-6 inline-flex flex-col">
                                  {item.childrenCollection.items
                                    .filter((x) => x)
                                    .map((x) => (
                                      <Link
                                        key={x?.sys.id}
                                        className="mt-4 first:mt-2 inline-flex text-sm font-normal cursor-pointer text-white bg-transparent"
                                        {...x}
                                      ></Link>
                                    ))}
                                </div>
                              )}
                            </div>
                          </MenuItem>
                        );

                        return null;
                      })}
                  </Menu>
                </div>
                <div
                  className="pr-4 w-full"
                  style={{
                    maxWidth: 264,
                  }}
                >
                  <div ref={animationRefs.containerSubMenuHighlight} className="w-full">
                    {item?.highlight && <PrimaryNavbarHighlight {...item?.highlight} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

// export default PrimaryNavBarLink;
export default React.memo(PrimaryNavBarLink);
