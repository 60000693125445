import {
  Contentful_SectorCollection,
  Contentful_ResearchAreaCollection,
  Contentful_DepartmentCollection,
} from "graphql-types";
import React from "react";
export const TagsContext:
  | Contentful_SectorCollection
  | Contentful_ResearchAreaCollection
  | Contentful_DepartmentCollection
  | any = React.createContext(null);
