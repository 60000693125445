import React, { useContext } from "react";
import { Contentful_SiteSettings } from "graphql-types";
import { Link } from "gatsby-plugin-intl";
import { SiteSettingsContext } from "../contexts/SiteSettingsContext";
import { ThemeContext } from "../contexts/ThemeContext";
import InlineSVG from "./inline-svg";

function Logo() {
  const SiteSettings: Contentful_SiteSettings = useContext(SiteSettingsContext);
  const color = useContext(ThemeContext);

  return (
    <>
      {SiteSettings.logo && (
        <Link to="/" className={`transition-colors duration-200 hover:text-slate-500`}>
          <InlineSVG image={SiteSettings.logo} className="w-auto max-w-none header-logo" />
        </Link>
      )}
    </>
  );
}

// export default Logo;
export default React.memo(Logo);
