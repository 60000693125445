import React, { useState } from "react";
import * as Yup from "yup";
import fetch from "isomorphic-unfetch";
import { Formik, Form, Field } from "formik";
import { useIntl } from "gatsby-plugin-intl";
import Arrow from "../../static/images/icons/arrow.svg";
import Spinner from "../../static/images/icons/spinner.svg";

interface FormValues {
  email: string;
  listId: string;
  tags: string[];
}

interface Props {
  listId: string;
  tags: (string | null)[] | null | undefined;
}

function SubscribedMessage() {
  const intl = useIntl();
  return (
    <div>
      <div className="text-sm block">{intl.formatMessage({ id: "footerNewsletterSubscribeHeading" })}</div>
      {intl.formatMessage({ id: "footerNewsletterSubscribeSuccess" })}
    </div>
  );
}

// tslint:disable-next-line: no-shadowed-variable
function SubscribeForm(props: any) {
  const intl = useIntl();
  return (
    <Form noValidate={true}>
      <label htmlFor="email" className="text-sm block">
        {intl.formatMessage({ id: "footerNewsletterSubscribeHeading" })}
      </label>

      <div className="relative inline-block">
        <span className="absolute inset-y-0 right-0 flex items-center pl-2">
          <button type="submit" className="p-1 focus:outline-none" disabled={props.isSubmitting}>
            {!props.isSubmitting && <Arrow className="h-4" />}
            {props.isSubmitting && <Spinner className="animate-spin h-4" />}
          </button>
        </span>
        <Field
          name="email"
          type="email"
          placeholder="email address"
          autoComplete="off"
          className="border-0 appearance-none bg-transparent focus:bg-transparent focus:outline-none border-b border-white placeholder-current p-5 pr-8 block"
          disabled={props.isSubmitting}
        />
      </div>

      {props.errors.email && props.touched.email ? (
        <div className="absolute text-sm pt-1 text-red-300">{props.errors.email}</div>
      ) : null}
    </Form>
  );
}

function SubscribeToNewsletter(props: Props) {
  const intl = useIntl();
  const [subscribed, setSubscribed] = useState(false);
  const initialValues: FormValues = {
    email: "",
    listId: props.listId,
    tags: props.tags ?? [],
  };

  const SignUpSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: "formValidation.emailInvalid" }))
      .required(intl.formatMessage({ id: "formValidation.emailRequired" })),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignUpSchema}
      onSubmit={(
        values: any,
        actions: {
          setFieldError: (arg0: string, arg1: string) => void;
          setSubmitting: (arg0: boolean) => void;
        }
      ) => {
        fetch("/.netlify/functions/mailchimp-subscribe", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        })
          .then((r) => {
            if (r.ok) {
              return r;
            } else {
              actions.setFieldError("email", intl.formatMessage({ id: "formValidation.unexpectedError" }));
              actions.setSubmitting(false);
              return Promise.reject(r.statusText);
            }
          })
          .then((r) => r.json())
          .then(() => {
            setSubscribed(true);
            actions.setSubmitting(false);
          });
      }}
    >
      {(controls: any) => (
        <div>
          {!subscribed && <SubscribeForm {...controls} />}
          {subscribed && <SubscribedMessage />}
        </div>
      )}
    </Formik>
  );
}

export default SubscribeToNewsletter;
