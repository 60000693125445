import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import Menu from "../components/menu";
import MenuItem from "../components/menu-item";
import NavLink from "../components/link";
import { Contentful_NavigationItem, Contentful_SiteSettingsFooterUsefulLinksCollection, Maybe } from "graphql-types";

function FooterUsefulLinks(collection: Contentful_SiteSettingsFooterUsefulLinksCollection) {
  const intl = useIntl();
  return (
    <>
      <h5 className="text-sm font-semibold pb-4">{intl.formatMessage({ id: "usefulLinksHeading" })}</h5>
      <Menu>
        {collection.items &&
          collection.items.map((item: Maybe<Contentful_NavigationItem>, key: number) => {
            if (item)
              return (
                <MenuItem {...item} className="pb-4" key={key}>
                  <NavLink {...item} />
                </MenuItem>
              );

            return null;
          })}
      </Menu>
    </>
  );
}

// export default FooterUsefulLinks;
export default React.memo(FooterUsefulLinks);
