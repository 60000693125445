import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { getCountryCodeAPI } from "../utils/geo-location";
import { useIntl } from "gatsby-plugin-intl";

interface LocationContextProps {
  locale: string;
  currentLocation: string | null;
  isLoading: boolean;
  setLocationManually: (location: string) => void;
  getCookieLocation: () => string | undefined;
  setCookieLocation: (code: string) => void;
}

const LocationContext = createContext<LocationContextProps | undefined>(undefined);

export const LocationProvider: React.FC = ({ children }) => {
  const { locale, defaultLocale } = useIntl();
  const [location, setLocation] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isManuallySet, setIsManuallySet] = useState(false);

  const getCookieLocation = () => Cookies.set("location");
  const setCookieLocation = (code: string) => Cookies.set("location", code, { expires: 7 });

  const fetchAndSetLocation = useCallback(async () => {
    setIsLoading(true);
    try {
      const countryCode = await getCountryCodeAPI({ defaultLocale });
      setLocation(countryCode);
      Cookies.set("location", countryCode);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [defaultLocale]);

  useEffect(() => {
    const initializeLocation = async () => {
      const savedLocation = Cookies.get("location");
      if (savedLocation) {
        setLocation(savedLocation);
      } else {
        if (!isManuallySet) {
          await fetchAndSetLocation();
        }
      }
      setIsLoading(false);
    };

    initializeLocation();
  }, [fetchAndSetLocation, isManuallySet]);

  const setLocationManually = (newLocation: string) => {
    setLocation(newLocation);
    Cookies.set("location", newLocation);
    setIsManuallySet(true);
  };

  return (
    <LocationContext.Provider
      value={{ locale, currentLocation: location, isLoading, setLocationManually, getCookieLocation, setCookieLocation }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export const useLocation = (): LocationContextProps => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error("useLocation must be used within a LocationProvider");
  }
  return context;
};
